import React from 'react';
import { RvCardList, RvHandler, RvCardListItem } from '../rearview_components';
import { css } from '@emotion/react';


export const ifRearview = (data) => {
  return data.some((node) => {
    return node.data.publish === true
  })
};


const RearviewInline = ({data, narrow}) => {
  
  return (
    <RvCardList
      css={[
        css`
          padding-top: 0;
        `,
      ]}
    >
      <RvHandler data={data} narrow={narrow} />
      <RvCardListItem placeholder />
      <RvCardListItem placeholder />
      <RvCardListItem placeholder />
    </RvCardList>
  )
}

export default RearviewInline;